<template>
  <v-snackbar class="toast" v-model="sShow" :timeout="timeout" :color="color" :top="position === 'top'" :bottom="position === 'bottom'" :left="position === 'left'" :right="position === 'right'" elevation="24">
    {{ sMsg }}
  </v-snackbar>
</template>

<script>
export default {
  props: {
    show: Boolean,
    msg: String,
    timeout: {
      type: Number,
      default: 3000,
    },
    color: {
      type: String,
      default: "primary",
    },
    position: {
      type: String,
      default: "bottom",
    },
  },
  data() {
    return {
      sShow: this.show,
      sMsg: this.msg,
    };
  },
  watch: {
    show(val) {
      this.sShow = val;
    },
    msg(val) {
      this.sMsg = val;
    },
  },
};
</script>
