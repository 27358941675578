<template>
  <div style="z-index: 2">
    <div
      class="navigation px-4 py-4 justify-space-around align-items-center bt-navigation"
      :style="$vuetify.theme.dark ? 'background: radial-gradient(circle, #290d30, #1e0924,#140618, #140618)' : 'background: radial-gradient(circle, #e0d2e4,#efe8f1,#fff, #fff)'"
      :class="showMenu ? 'd-flex' : 'd-none'"
    >
      <router-link
        v-for="item in items"
        :key="item.title"
        :to="item.path"
        class="d-flex align-center rounded-lg pa-3"
        :class="isActiveParent(item) ? 'purple darken-2' : 'grey lighten-4 elevation-3'"
        :style="$vuetify.theme.dark ? 'background-color: rgba(66, 66, 66)' : 'background-color: #fff'"
      >
        <v-icon role="img" :color="isActiveParent(item) ? 'purple lighten-5' : 'purple darken-1'" dense>
          {{ item.icon }}
        </v-icon>
      </router-link>
    </div>
    <div
      class="navigation px-4 py-5 bt-navigation justify-center align-items-center"
      :style="$vuetify.theme.dark ? 'background: radial-gradient(circle, #290d30, #1e0924,#140618, #140618)' : 'background: radial-gradient(circle, #e0d2e4,#efe8f1,#fff, #fff)'"
      :class="showBack ? 'd-flex' : 'd-none'"
    >
      <div @click="goBack" class="d-flex align-center py-2" style="cursor: pointer">
        <v-icon>mdi-arrow-left-bold-circle</v-icon>
        <span class="ml-2 font-weight-bold">Ke Halaman Sebelumnya</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        title: "Home",
        path: "/home",
        icon: "mdi-home",
      },
      {
        title: "Keanggotaan",
        path: "/keanggotaan/dashboard",
        icon: "mdi-account-group",
        children: ["/keanggotaan/registrasiMasuk", "/keanggotaan/registrasiKeluar"],
      },
      {
        title: "DTL",
        path: "/dtl/dashboard",
        icon: "mdi-pencil-circle",
        children: [
          // ANGSURAN
          "/angsuran/kumpulan",
          "/angsuran/kelompok/",
          "/angsuran/anggota/",
          "/angsuran/infaq/",
          // PEMBIAYAAN
          "/pembiayaan/pencairan",
          "/pembiayaan/pengajuan",
          // TABUNGAN
          "/tabungan/setor_tarik",
          "/tabungan/kelompok/",
          "/tabungan/anggota/",
          // OTHER
          "/others/wakaf/",
        ],
      },
      {
        title: "LHP",
        path: "/lhp/dashboard",
        icon: "mdi-note-multiple-outline",
        children: [
          // PENAGIHAN
          "/penagihan/dashboard",
          "/penagihan/form_penagihan",
          // PENILAIAN
          "/penilaian/dashboard",
        ],
      },
      {
        title: "Kirim",
        path: "/send",
        icon: "mdi-send",
      },
    ],
  }),

  methods: {
    isActiveParent(item) {
      const currentPath = this.$route.path;

      // Jika item ini memiliki child, cek apakah currentPath termasuk dalam child
      if (item.children && item.children.some((childPath) => currentPath.startsWith(childPath))) {
        return true;
      }

      // Jika tidak ada child, langsung cek apakah path utama cocok
      return currentPath.startsWith(item.path);
    },

    goBack() {
      if (window.history.length > 1) {
        this.$router.go(-1);
      } else {
        this.$router.push("/");
      }
    },
  },
  computed: {
    showMenu() {
      return this.$route.name ? !["Login", "Forgot", "Detail Penagihan", "Detail Penilaian", "Laporan Penagihan"].includes(this.$route.name) : false;
    },
    showBack() {
      return this.$route.name ? ["Detail Penagihan", "Detail Penilaian", "Laporan Penagihan"].includes(this.$route.name) : false;
    },
  },
};
</script>
