<template>
  <div class="backCustom pa-2" :class="showTitle ? 'd-flex' : 'd-none'">
    <v-col class="d-flex align-center" cols="6">
      <div class="status-indicator d-flex align-center" :class="isOnline ? 'bg-online' : 'bg-offline'">
        <v-icon dense class="status-icon" :class="isOnline ? 'online-icon' : 'offline-icon'">
          {{ isOnline ? "mdi-signal" : "mdi-signal-off" }}
        </v-icon>
      </div>
      <div>
        <h5 class="ml-3" :class="isOnline ? 'white--text' : 'red--text'">
          {{ isOnline ? "Online Mode" : "Offline Mode" }}
        </h5>
      </div>
    </v-col>
    <v-col cols="6">
      <div class="d-flex justify-end align-center">
        <DarkMode />
      </div>
    </v-col>
  </div>
</template>
<script>
import DarkMode from "@/components/DarkMode.vue";
export default {
  data() {
    return {
      isOnline: navigator.onLine, // Mengecek status awal jaringan
    };
  },
  components: {
    DarkMode,
  },
  computed: {
    showTitle() {
      return this.$route.name ? !["Login", "Forgot"].includes(this.$route.name) : false;
    },
  },
  mounted() {
    // Menambahkan event listener untuk mendeteksi perubahan status jaringan
    window.addEventListener("online", this.updateNetworkStatus);
    window.addEventListener("offline", this.updateNetworkStatus);
  },
  beforeDestroy() {
    // Menghapus event listener saat komponen dihancurkan
    window.removeEventListener("online", this.updateNetworkStatus);
    window.removeEventListener("offline", this.updateNetworkStatus);
  },
  methods: {
    updateNetworkStatus() {
      this.isOnline = navigator.onLine; // Memperbarui status jaringan
    },
  },
};
</script>
<style scoped>
.status-indicator {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  border-radius: 50%;
  transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

/* Status Online */
.bg-online {
  cursor: pointer;
  position: relative;
  transition: background 0.3s ease-in-out, box-shadow 0.2s ease-in-out;
  background: linear-gradient(145deg, #28a745, #185e27);
  box-shadow: inset 1px 1px 2px 1px rgba(0, 0, 0, 0.3), inset 2px 2px 6px 6px rgba(124, 124, 124, 0.18);
}

/* Status Offline */
.bg-offline {
  cursor: pointer;
  position: relative;
  transition: background 0.3s ease-in-out, box-shadow 0.2s ease-in-out;
  background: linear-gradient(145deg, #dc3545, #c82333);
  box-shadow: inset 1px 1px 2px 1px rgba(0, 0, 0, 0.3), inset 2px 2px 6px 6px rgba(124, 124, 124, 0.18);
}

/* Ikon */
.status-icon {
  padding: 5px;
  border-radius: 50%;
  font-size: 12px;
  transition: transform 0.3s ease-in-out;
}

/* Efek untuk Online */
.online-icon {
  color: white;
  background: linear-gradient(145deg, #28a745, #185e27);
  box-shadow: inset 1px 1px 10px rgba(222, 222, 222, 0.3), inset -3px -3px 1px rgba(124, 124, 124, 0.18);
}

/* Efek untuk Offline */
.offline-icon {
  color: white;
  background: linear-gradient(145deg, #dc3545, #c82333);
  box-shadow: inset 1px 1px 10px rgba(222, 222, 222, 0.3), inset -3px -3px 1px rgba(124, 124, 124, 0.18);
}
</style>
