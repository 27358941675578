<template>
  <div class="akr-title titleCustom w-100 bg-title" :class="showTitle ? 'd-flex' : 'd-none'">
    <v-container fluid>
      <v-row align="center" justify="space-between" no-gutters>
        <v-col class="bt-left d-flex align-center pl-1">
          <h4 class="white--text text--lighten-1 text-uppercase">
            {{ title }}
          </h4>
        </v-col>

        <v-col class="bt-right d-flex justify-end">
          <div class="d-flex justify-center align-center pr-2">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <div class="d-flex align-center">
                  <span class="white--text text-subtitle-1 font-weight-bold mr-2">Hi, {{ shortUserName }}... </span>
                  <v-btn v-bind="attrs" v-on="on" icon>
                    <v-avatar size="30">
                      <img src="../images/profile.png" alt="Profile" />
                    </v-avatar>
                  </v-btn>
                </div>
              </template>

              <v-list dense class="text-right rounded-lg pa-3" :class="{ 'dark-bg': $vuetify.theme.dark, 'light-bg': !$vuetify.theme.dark }">
                <v-list-item class="text-caption font-weight-bold">
                  <span class="text-uppercase">{{ user.nama_user }}</span>
                </v-list-item>
                <v-list-item class="text-caption font-weight-bold">
                  <span>{{ user.branch_name }}</span>
                </v-list-item>
                <v-list-item class="text-caption font-weight-bold">
                  <span>{{ userRole.role_name }}</span>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="doLogout">
                  <v-list-item-title>
                    <span class="pa-2 text-caption font-weight-bold text-uppercase">Keluar</span>
                    <v-icon class="ml-2" color="purple lighten-1">mdi-logout</v-icon>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="showLogoutAlert" max-width="400" overlay-opacity="0.3" persistent>
      <v-card>
        <v-card-title class="headline red--text">
          <v-icon color="red darken-2" class="mr-2">mdi-alert-circle</v-icon>
          Perhatian!
        </v-card-title>
        <v-divider class="my-3" />
        <v-card-text>
          Akun Anda telah digunakan untuk login di perangkat atau browser lain. Anda akan logout otomatis dalam <strong>{{ countdown }}</strong> detik.
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="red darken-2" dark @click="confirmLogout">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getData, deleteData } from "@/utils/indexedDB";
import services from "@/services";

export default {
  data() {
    return {
      showLogoutAlert: false,
      countdown: 10,
      countdownTimer: null,
    };
  },
  props: {
    title: String,
  },
  computed: {
    ...mapGetters(["user", "userRole"]),

    showTitle() {
      return this.$route.name && !["Login", "Forgot", "Register"].includes(this.$route.name);
    },

    shortUserName() {
      return this.user.nama_user?.split(" ")[0] ?? "";
    },
  },
  methods: {
    ...mapActions(["logout"]),

    async doLogout() {
      await deleteData("user_data", "user");
      this.logout();

      if (this.$route.path !== "/login") {
        this.$router.push("/login");
      }
    },
    async getInfo() {
      try {
        if (!this.user?.account_cash_code) return;

        const payload = new FormData();
        payload.append("account_cash_code", this.user.account_cash_code);

        if (!this.user.token) {
          console.warn("Token tidak ditemukan, logout otomatis...");
          this.doLogout();
          return;
        }

        const response = (await services.infoSaldoPetugas(payload, this.user.token)).data;

        if (response?.status === false && response?.msg?.toLowerCase().includes("token")) {
          this.showLogoutWarning();
        }
      } catch (error) {
        console.error("Error getInfo:", error);
      }
    },

    showLogoutWarning() {
      console.log("Logout warning triggered");
      this.showLogoutAlert = true;
      this.startCountdown();
    },

    startCountdown() {
      this.countdown = 5;
      console.log("Memulai countdown logout...");

      this.countdownTimer = setInterval(() => {
        console.log("Countdown: ", this.countdown);
        if (this.countdown > 1) {
          this.countdown--;
        } else {
          this.confirmLogout();
        }
      }, 1000);
    },

    confirmLogout() {
      console.log("Melakukan logout...");
      clearInterval(this.countdownTimer);
      this.showLogoutAlert = false;
      this.doLogout();
    },
  },
  mounted() {
    this.getInfo();
  },
};
</script>

<style scoped>
.dark-bg {
  background-color: rgba(24, 24, 24, 0.9);
}
.light-bg {
  background-color: rgba(255, 255, 255, 0.9);
}

.v-overlay__scrim {
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.3) !important;
}
</style>
