<template>
  <v-app class="purple darken-4">
    <Toast :show="$store.state.alert.show" :msg="$store.state.alert.msg" :color="$store.state.alert.color" :position="$store.state.alert.position" />
    <!-- Loading Overlay -->
    <v-overlay :value="$store.state.isLoading" :color="$vuetify.theme.dark ? 'black' : 'white'" opacity="0.8" z-index="9999">
      <Loader />
    </v-overlay>
    <div id="tpl-app" class="d-flex mx-auto flex-column">
      <TitleBar :title="$route.name" />
      <v-main class="bt-content pb-12">
        <Navbar />
        <router-view />
        <v-dialog v-model="updateDialog" persistent max-width="290" style="z-index: 9999">
          <v-card>
            <v-card-title class="text-h5"> Update </v-card-title>
            <v-card-text>Update sistem tersedia! Update Sekarang!</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="reloadPage"> Update </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-main>
      <Navigation />
    </div>

    <div class="update-dialog" v-if="prompt">
      <div class="update-dialog__content">A new version is found. Refresh to load it?</div>
      <div class="update-dialog__actions">
        <button class="update-dialog__button update-dialog__button--confirm" @click="update">Update</button>
        <button class="update-dialog__button update-dialog__button--cancel" @click="prompt = false">Cancel</button>
      </div>
    </div>
  </v-app>
</template>

<script>
import Navigation from "./components/Navigation.vue";
import TitleBar from "./components/TitleBar.vue";
import Navbar from "./components/Navbar.vue";
import Loader from "./components/Loader.vue";
import Toast from "./components/Toast.vue";
import { EventBus } from "./eventBus";
export default {
  name: "App",
  components: {
    Navigation,
    TitleBar,
    Navbar,
    Loader,
    Toast,
  },
  methods: {
    async update() {
      this.prompt = false;
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    },
    reloadPage() {
      window.location.reload();
    },
  },
  data() {
    return {
      prompt: false,
      updateDialog: false,
    };
  },
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.showUpdateUI = true;
      });
    }
    EventBus.$on("show-update-dialog", () => {
      if (this.$route.name !== "Login" && this.$store.state.isAuthenticated) {
        this.updateDialog = true;
        console.log("updateDialog diubah menjadi:", this.updateDialog);
      }
    });
  },
};
</script>

<style lang="scss">
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
//   margin-top: 60px;
// }

.update-dialog {
  position: fixed;
  left: 50%;
  bottom: 64px;
  transform: translateX(-50%);
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 12px;
  max-width: 576px;
  color: white;
  background-color: #2c3e50;
  text-align: left;

  &__actions {
    display: flex;
    margin-top: 8px;
  }

  &__button {
    margin-right: 8px;

    &--confirm {
      margin-left: auto;
    }
  }
}
</style>
