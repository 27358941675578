import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: localStorage.getItem("easycoTpl") != null ? JSON.parse(localStorage.getItem("easycoTpl")) : [],
    userRole: localStorage.getItem("userRole") != null ? JSON.parse(localStorage.getItem("userRole")) : [],
    transaksi: localStorage.getItem("easycoTrx") != null ? JSON.parse(localStorage.getItem("easycoTrx")) : [],
    kasPetugas: localStorage.getItem("easycoKasPetugas") != null ? JSON.parse(localStorage.getItem("easycoKasPetugas")) : [],
    transaksiRembug: localStorage.getItem("easycoTrxRembug") != null ? JSON.parse(localStorage.getItem("easycoTrxRembug")) : [],
    isLoading: false,
    alert: {
      show: false,
      msg: "",
      color: "",
      position: "",
    },
  },
  getters: {
    user: (state) => state.user,
    userRole: (state) => state.userRole,
    transaksi: (state) => state.transaksi,
    transaksiRembug: (state) => state.transaksiRembug,
    kasPetugas: (state) => state.kasPetugas,
  },
  mutations: {
    SET_ALERT(state, payload) {
      state.alert = payload;
    },
    setLoading(state, status) {
      state.isLoading = status;
    },
    setUser(state, payload) {
      state.user = payload;
      localStorage.setItem("easycoTpl", JSON.stringify(state.user));
    },
    setUserRole(state, payload) {
      state.userRole = payload;
      localStorage.setItem("userRole", JSON.stringify(state.userRole));
    },
    removeUser(state) {
      state.user = [];
      localStorage.removeItem("easycoTpl");
      localStorage.removeItem("userRole");
    },
    setTrx(state, payload) {
      state.transaksi = payload;
      localStorage.setItem("easycoTrx", JSON.stringify(state.transaksi));
    },
    clearTrx(state) {
      state.transaksi = [];
      localStorage.removeItem("easycoTrx");
    },
    setTrxRembug(state, payload) {
      state.transaksiRembug = payload;
      localStorage.setItem("easycoTrxRembug", JSON.stringify(state.transaksiRembug));
    },
    setKasPetugas(state, payload) {
      state.kasPetugas = payload;
      localStorage.setItem("easycoKasPetugas", JSON.stringify(state.kasPetugas));
    },
  },
  actions: {
    showAlert({ commit }, payload) {
      commit("SET_ALERT", payload);
    },
    login({ commit }, payload) {
      commit("setUser", payload);
    },
    setUserRole({ commit }, payload) {
      commit("setUserRole", payload);
    },
    logout({ commit }) {
      commit("removeUser");
    },
    setTrx({ commit }, payload) {
      commit("setTrx", payload);
    },
    clearTrx({ commit }) {
      commit("clearTrx");
    },
    setKasPetugas({ commit }, payload) {
      commit("setKasPetugas", payload);
    },
  },
  modules: {},
});
