// INI VERSI PERTAMA
// // registerServiceWorker.js
// import { register } from "register-service-worker";

// register(`${process.env.BASE_URL}service-worker.js`, {
//   ready(registration) {
//     console.log(
//       "App is being served from cache by a service worker.\n",
//       registration
//     );
//   },
//   registered(registration) {
//     console.log("Service worker has been registered.\n", registration);
//   },
//   cached(registration) {
//     console.log("Content has been cached for offline use.\n", registration);
//   },
//   updatefound(registration) {
//     console.log("New content is downloading.\n", registration);
//   },
//   updated(registration) {
//     console.log("New content is available; please refresh.\n", registration);
//   },
//   offline() {
//     console.log(
//       "No internet connection found. App is running in offline mode."
//     );
//   },
//   error(error) {
//     console.error("Error during service worker registration:", error);
//   },
// });

// INI VERSI KEDUA UPDATE UMAR
import { register } from "register-service-worker";
import { EventBus } from "./eventBus";

register(`${process.env.BASE_URL}service-worker.js`, {
  ready() {
    console.log("Aplikasi berjalan dalam mode PWA.");
  },
  registered() {
    console.log("Service Worker terdaftar.");
  },
  cached() {
    console.log("Konten telah dicache untuk penggunaan offline.");
  },
  updatefound() {
    console.log("Service Worker baru ditemukan, mengunduh...");
  },
  updated(registration) {
    console.log("Update tersedia! Membersihkan cache lama...");

    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });

      // Tunggu service worker baru aktif, lalu refresh halaman
      registration.waiting.addEventListener("statechange", (event) => {
        if (event.target.state === "activated") {
          console.log("Service worker baru aktif. Reloading...");

          // Hapus cache lama sebelum reload
          caches.keys().then((cacheNames) => {
            Promise.all(
              cacheNames.map((cacheName) => {
                if (cacheName.startsWith("workbox")) {
                  console.log(`Menghapus cache lama: ${cacheName}`);
                  return caches.delete(cacheName);
                }
              })
            ).then(() => {
              EventBus.$emit("show-update-dialog");
            });
          });
        }
      });
    }
  },
  offline() {
    console.log("Aplikasi berjalan dalam mode offline.");
  },
  error(error) {
    console.error("Service Worker error:", error);
  },
});
