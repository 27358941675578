import Vue from "vue";
import VueRouter from "vue-router";
import App from "../views";
import AuthRequired from "../utils/AuthRequired";
import store from "@/store";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "App",
    component: App,
    beforeEnter: AuthRequired,
    redirect: "/home",
    children: [
      {
        path: "home",
        name: "Home",
        component: () => import("../views/HomeV2.vue"),
      },
      {
        path: "profile",
        name: "Profile",
        component: () => import("../views/Profile.vue"),
      },
      {
        path: "transaksi",
        name: "Transaksi",
        component: () => import("../views/Transaksi"),
        redirect: "/transaksi/dashboard",
        children: [
          {
            path: "dashboard/:no_anggota?",
            name: "Dashboard",
            component: () => import("../views/Transaksi/Dashboard.vue"),
          },
          {
            path: "setoran",
            name: "Setoran",
            component: () => import("../views/Transaksi/Setoran.vue"),
          },
        ],
      },
      {
        path: "angsuran",
        name: "Angsuran",
        component: () => import("../views/Angsuran"),
        redirect: "/angsuran/kumpulan",
        children: [
          {
            path: "kumpulan",
            name: "Angsuran Kumpulan",
            component: () => import("../views/Angsuran/AngsuranKumpulan.vue"),
          },
          {
            path: "kelompok/:cm_code?/:branch_id?/:tanggal?",
            name: "Angsuran Kelompok",
            component: () => import("../views/Angsuran/AngsuranKelompok.vue"),
          },
          {
            path: "anggota/:cm_code?/:branch_id?/:kode_kelompok?/:transaction_date?",
            name: "Angsuran Anggota",
            component: () => import("../views/Angsuran/AngsuranAnggota.vue"),
          },
          {
            path: "infaq/:cm_code?/:branch_id?/:transaction_date?/:infaq?",
            name: "Infaq Anggota",
            component: () => import("../views/Angsuran/InfaqAnggota.vue"),
          },
        ],
      },
      {
        path: "keanggotaan",
        name: "Keanggotaan",
        component: () => import("../views/Keanggotaan"),
        redirect: "/keanggotaan/dashboard",
        children: [
          {
            path: "dashboard",
            name: "Keanggotaan Dashboard",
            component: () => import("../views/Keanggotaan/Dashboard.vue"),
          },
          {
            path: "registrasiMasuk",
            name: "Keanggotaan Registrasi Masuk",
            component: () => import("../views/Keanggotaan/RegistrasiMasuk/index.vue"),
          },
          {
            path: "registrasiKeluar",
            name: "Keanggotaan Registrasi Keluar",
            component: () => import("../views/Keanggotaan/RegistrasiKeluar/index.vue"),
          },
        ],
      },
      {
        path: "pembiayaan",
        name: "Pembiayaan",
        component: () => import("../views/Pembiayaan"),
        redirect: "/pembiayaan/dashboard",
        children: [
          {
            path: "dashboard",
            name: "Pembiayaan Dashboard",
            component: () => import("../views/Pembiayaan/Dashboard.vue"),
          },
          {
            path: "pencairan",
            name: "Pembiayaan Pencairan",
            component: () => import("../views/Pembiayaan/Pencairan.vue"),
          },
          {
            path: "pengajuan",
            name: "Pembiayaan Pengajuan",
            component: () => import("../views/Pembiayaan/Pengajuan.vue"),
          },
        ],
      },
      {
        path: "lhp",
        name: "Lhp",
        component: () => import("../views/Lhp"),
        redirect: "/lhp/dashboard",
        children: [
          {
            path: "dashboard",
            name: "Lhp Dashboard",
            component: () => import("../views/Lhp/Dashboard.vue"),
          },
          {
            path: "kolek_cabang",
            name: "Kolek Cabang",
            component: () => import("../views/Lhp/KolekCabang.vue"),
          },
          {
            path: "kumpulan/:cm_code?/:branch_id?/:tanggal?",
            name: "Kumpulan",
            component: () => import("../views/Lhp/Kumpulan.vue"),
          },
          {
            path: "pembayaran/:cm_code?/:branch_id?/:kode_kelompok?/:transaction_date?",
            name: "Pembayaran Lhp",
            component: () => import("../views/Lhp/PembayaranLhp.vue"),
          },
        ],
      },
      {
        path: "tabungan",
        name: "Tabungan",
        component: () => import("../views/Tabungan"),
        redirect: "/tabungan/dashboard",
        children: [
          {
            path: "setor_tarik",
            name: "Tabungan Setor Tarik",
            component: () => import("../views/Tabungan/SetorTarik.vue"),
          },
          {
            path: "kelompok/:cm_code?/:branch_id?/:tanggal?",
            name: "Tabungan Kelompok",
            component: () => import("../views/Tabungan/TabunganKelompok.vue"),
          },
          {
            path: "anggota/:cm_code?/:branch_id?/:kode_kelompok?/:transaction_date?",
            name: "Tabungan Anggota",
            component: () => import("../views/Tabungan/TabunganAnggota.vue"),
          },
          {
            path: "dashboard",
            name: "Tabungan Dashboard",
            component: () => import("../views/Tabungan/Dashboard.vue"),
          },
          {
            path: "pencairan",
            name: "Tabungan Pencairan",
            component: () => import("../views/Tabungan/Pencairan.vue"),
          },
          {
            path: "registrasi",
            name: "Tabungan Registrasi",
            component: () => import("../views/Tabungan/Registrasi.vue"),
          },
        ],
      },
      {
        path: "others",
        name: "others",
        component: () => import("../views/Others"),
        redirect: "/others/dashboard",
        children: [
          {
            path: "dashboard",
            name: "others Dashboard",
            component: () => import("../views/Others/Dashboard.vue"),
          },
          {
            path: "ppob",
            name: "others Pencairan",
            component: () => import("../views/Others/PPOB.vue"),
          },
          {
            path: "wakaf",
            name: "others Registrasi",
            component: () => import("../views/Others/Wakaf/index.vue"),
            redirect: "/others/wakaf/kumpulan",
            children: [
              {
                path: "kumpulan",
                name: "Wakaf Kumpulan",
                component: () => import("../views/Others/Wakaf/WakafKumpulan.vue"),
              },
              {
                path: "kelompok/:cm_code?/:branch_id?/:tanggal?",
                name: "Wakaf Kelompok",
                component: () => import("../views/Others/Wakaf/WakafKelompok.vue"),
              },
              {
                path: "anggota/:cm_code?/:branch_id?/:kode_kelompok?/:transaction_date?",
                name: "Wakaf Anggota",
                component: () => import("../views/Others/Wakaf/WakafAnggota.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "dtl",
        name: "DTL",
        component: () => import("../views/Dtl/Dashboard.vue"),
        redirect: "/Dtl/Dashboard",
        children: [
          {
            path: "dashboard",
            name: "DTL Dashboard",
            component: () => import("../views/Dtl/Dashboard.vue"),
          },
        ],
      },
      {
        path: "penagihan",
        name: "penagihan",
        component: () => import("../views/Penagihan"),
        redirect: "/penagihan/dashboard",
        children: [
          {
            path: "dashboard",
            name: "Penagihan Dashboard",
            component: () => import("../views/Penagihan/Dashboard.vue"),
          },
          {
            path: "detail/:id",
            name: "Detail Penagihan",
            component: () => import("../views/Penagihan/Detail/DetailPenagihan.vue"),
          },
          {
            path: "form_penagihan",
            name: "Form Penagihan",
            component: () => import("../views/Penagihan/Form/FormPenagihan.vue"),
          },
          {
            path: "laporan_penagihan",
            name: "Laporan Penagihan",
            component: () => import("../views/Penagihan/Laporan/LaporanPenagihan.vue"),
          },
        ],
      },
      {
        path: "penilaian",
        name: "penilaian",
        component: () => import("../views/Penilaian"),
        redirect: "/penilaian/dashboard",
        children: [
          {
            path: "dashboard",
            name: "penilaian Dashboard",
            component: () => import("../views/Penilaian/Dashboard.vue"),
          },
          {
            path: "detail/:id",
            name: "Detail Penilaian",
            component: () => import("../views/Penilaian/Detail/DetailPenilaian.vue"),
          },
        ],
      },
      {
        path: "laporan",
        name: "Laporan",
        component: () => import("../views/Laporan/Lhp/index.vue"),
      },
      {
        path: "/send",
        name: "Send",
        component: () => import("../views/Send.vue"),
      },
    ],
  },
  { path: "*", name: "NotFound", component: () => import("../views/NotFound.vue") },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/forgot",
    name: "Forgot",
    component: () => import("../views/Forgot.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Tampilkan loading sebelum pergantian halaman
router.beforeEach((to, from, next) => {
  store.commit("setLoading", true);
  next();
});

// Sembunyikan loading setelah halaman selesai dimuat
router.afterEach(() => {
  setTimeout(() => {
    store.commit("setLoading", false);
  }, 500); // Delay 500ms, bisa disesuaikan
});

export default router;
