import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueMask from "v-mask";
import VueSignature from "vue-signature-pad";
import "./registerServiceWorker";
import { themeStore } from "./theme.js";
import "@/services/dataDummy";
import "./assets/main.scss";

Vue.use(VueMask);
Vue.config.productionTip = false;
Vue.use(VueSignature);

new Vue({
  router,
  store,
  vuetify,
  created() {
    this.$vuetify.theme.dark = themeStore.darkMode;
  },
  render: (h) => h(App),
}).$mount("#app");
