import Vue from "vue";

export const themeStore = Vue.observable({
  darkMode: JSON.parse(localStorage.getItem("darkMode")) || false,
});

export const setDarkMode = (value) => {
  themeStore.darkMode = value;
  localStorage.setItem("darkMode", JSON.stringify(value));
};
