import { openDB } from "idb";
import CryptoJS from "crypto-js";
import services from "@/services";

const DB_NAME = "akrs_db";
const DB_VERSION = 1;
const SECRET_KEY = "akrsyariah-microfinance";

// Daftar Store
const STORES = {
  USER: "user_data",
  KAS_PETUGAS: "kas_petugas",
  PEMBIAYAAN: "count_pembiayaan",
  TABUNGAN: "count_tabungan",
  ANGGOTA: "count_anggota",
  // PENAGIHAN
  INFO_TERTUNGGAK: "info_tertunggak",
  FORM_PENAGIHAN: "form_penagihan",
  LIST_PENAGIHAN: "list_penagihan",
  INFO_PENAGIHAN: "info_penagihan",
};

export async function initDB() {
  return openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      Object.values(STORES).forEach((store) => {
        if (!db.objectStoreNames.contains(store)) {
          db.createObjectStore(store, { keyPath: "id" });
        }
      });
    },
  });
}

export async function checkAndResetDB() {
  const db = await openDB(DB_NAME, DB_VERSION);
  const existingStores = new Set(db.objectStoreNames);
  const requiredStores = new Set(Object.values(STORES));

  if ([...existingStores].sort().join() !== [...requiredStores].sort().join()) {
    console.warn("🔄 Reset IndexedDB karena struktur tidak cocok!");
    await db.close();
    await indexedDB.deleteDatabase(DB_NAME);
    return initDB();
  }
}

// 🔹 Enkripsi Data
function encryptData(data) {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
}

// 🔹 Dekripsi Data
export function decryptData(encryptedData) {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch {
    return null;
  }
}
function convertFormDataToObject(formData) {
  const object = {};
  formData.forEach((value, key) => {
    object[key] = value;
  });
  return object;
}
export async function addData(store, data) {
  const db = await initDB();
  const id = Date.now().toString();
  const encrypted = encryptData({ id, data, timestamp: new Date().toISOString() });
  await db.add(store, { id, encrypted });
}

// 🔹 Simpan Data ke IndexedDB (Generik)
export async function saveData(store, data, id = "default") {
  const db = await initDB();
  const encrypted = encryptData({ id, data, timestamp: new Date().toISOString() });
  await db.put(store, { id, encrypted });
}
export async function addDataWithoutEncryption(store, data) {
  const db = await initDB();
  const id = Date.now().toString();
  const entry = {
    id,
    data: data instanceof FormData ? convertFormDataToObject(data) : data,
    timestamp: new Date().toISOString(),
  };
  await db.add(store, { id, entry });
}

// 🔹 Ambil Data dari IndexedDB
export async function getData(store, id = "default") {
  const db = await initDB();
  const result = await db.get(store, id);
  return result ? decryptData(result.encrypted) : null;
}

export async function getAllData(store) {
  const db = await initDB();
  const allData = await db.getAll(store);
  return allData;
}

// 🔹 Hapus Data
export async function deleteData(store, id = "default") {
  const db = await initDB();
  await db.delete(store, id);
}

export async function downloadAndSaveData(user, onProgress, onError) {
  await checkAndResetDB();

  let payload = new FormData();
  payload.append("account_cash_code", user.account_cash_code);
  payload.append("fa_code", user.fa_code);
  payload.append("branch_code", user.branch_code);

  console.log(STORES.ANGGOTA);

  const endpoints = [
    {
      store: STORES.KAS_PETUGAS,
      request: () => services.infoSaldoPetugas(payload, user.token),
    },
    {
      store: STORES.PEMBIAYAAN,
      request: () => services.infoFinancing(payload, user.token),
    },
    {
      store: STORES.TABUNGAN,
      request: () => services.infoSaving(payload, user.token),
    },
    {
      store: STORES.ANGGOTA,
      request: () => services.infoInOutMember(payload, user.token),
    },
    {
      store: STORES.INFO_TERTUNGGAK,
      request: () => services.infoTertunggak(payload, user.token),
    },
    {
      store: STORES.LIST_PENAGIHAN,
      request: () => services.listPenagihan(payload, user.token),
    },
    {
      store: STORES.INFO_PENAGIHAN,
      request: () => services.infoPenagihan(payload, user.token),
    },
  ];

  try {
    let progressBar = 0;
    const total = endpoints.length;

    await Promise.all(
      endpoints.map(async ({ store, request }) => {
        try {
          const response = await request();
          if (!response) throw new Error(`Gagal mengambil data dari ${store}`);
          await saveData(store, response, store);
          progressBar += 1;
          onProgress(progressBar / total);
        } catch (error) {
          console.error(`❌ Gagal mengambil data dari ${store}:`, error);
        }
      })
    );
  } catch (error) {
    console.error("❌ Terjadi kesalahan besar:", error);
    onError(error.message);

    await Promise.all(endpoints.map(({ store }) => deleteData(store)));
  }
}
