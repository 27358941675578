import { getData, deleteData } from "@/utils/indexedDB";
import store from "@/store";

export default async (to, from, next) => {
  try {
    const user = await getData("user_data", "user");
    if (user?.data?.token || (localStorage.getItem("easycoTpl") != null && localStorage.getItem("easycoTpl").length > 0)) {
      next();
    } else {
      await deleteData("user_data", "user");
      await store.dispatch("logout");
      next("/login");
    }
  } catch (error) {
    console.error("Error checking user session:", error);
    await deleteData("user_data", "user");
    await store.dispatch("logout");
    next("/login");
  }
};
