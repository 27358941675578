import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

// AUTH
const authCheckUsername = (payload) => {
  return axios.post("/auth/check_username", payload);
};

const authCheckPassworLogin = (payload) => {
  return axios.post("/auth/check_password", payload);
};

const authCheckUserRole = (payload) => {
  return axios.post("/auth/check_role", payload);
};
// END AUTH

// INFORMATION
const infoSaldoPetugas = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/information/cash", payload, config);
};

const infoInOutMember = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/information/info_member", payload, config);
};

const infoFinancing = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/information/info_financing", payload, config);
};
const infoSaving = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/information/info_saving", payload, config);
};

const infoRembug = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/information/cm", payload, config);
};

const infoGroup = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/information/group_financing", payload, config);
};

const infoGroupSaving = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/information/group_saving", payload, config);
};

const infoMemberFinancing = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/information/member_financing", payload, config);
};

const produkTabungan = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/information/product_saving", payload, config);
};

const TabunganDetail = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/information/detail_saving", payload, config);
};

const infoDetailAngsuran = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/information/detail_angsuran", payload, config);
};

const infoMemberSaving = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/information/member_saving", payload, config);
};
// END INFORMATION

// TRANSACTION
const transSetoranDeposit = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/transaction/deposit", payload, config);
};

const transSetoranProses = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/transaction/process_deposit", payload, config);
};

const registrasiTabungan = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/transaction/tabungan", payload, config);
};

const pencairanTabungan = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/transaction/pencairan_tabungan", payload, config);
};

const prosesPencairan = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/transaction/droping", payload, config);
};

const transactionSetorAngsuran = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/transaction/setor_angsuran", payload, config);
};

const transactionTabungan = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/transaction/transaksi_tabungan", payload, config);
};

const infoGroupWakaf = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/information/group_wakaf", payload, config);
};

const infoMemberWakaf = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/information/member_wakaf", payload, config);
};

const transactionSetorWakaf = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/transaction/setor_wakaf", payload, config);
};

const infoBirthPlace = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.get("/information/birthplace?" + payload, config);
};

const infoPendidikan = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.get("/information/education?" + payload, config);
};

const infoOccupation = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.get("/information/occupation?" + payload, config);
};

const infoCity = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.get("/information/city?" + payload, config);
};

const infoDistrict = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.get("/information/district?" + payload, config);
};

const infoVillage = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.get("/information/village?" + payload, config);
};

const infoHouseStatus = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.get("/information/house_status?" + payload, config);
};

const infoSector = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.get("/information/sector?" + payload, config);
};

const infoBusiness = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.get("/information/business?" + payload, config);
};

const infoHouseWater = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.get("/information/house_water?" + payload, config);
};

const infoHouseToilet = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.get("/information/house_toilet?" + payload, config);
};

const infoHouseFloor = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.get("/information/house_floor?" + payload, config);
};

const infoHouseRoof = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.get("/information/house_roof?" + payload, config);
};

const infoHouseWall = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.get("/information/house_wall?" + payload, config);
};

const infoHouseSize = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.get("/information/house_size?" + payload, config);
};

const infoCheckKTP = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/information/check_ktp", payload, config);
};

const infoAge = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/information/age", payload, config);
};

const infoMember = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/information/member", payload, config);
};

const memberDroping = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/information/member_droping", payload, config);
};

const infoSaldo = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/information/info_saldo", payload, config);
};

const infoReason = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.get("/information/reason?" + payload, config);
};

const transactionRegisAnggotaMasuk = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/transaction/regis_anggota", payload, config);
};

const transactionRegisAnggotaKeluar = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/transaction/regis_keluar", payload, config);
};

const infoAllocation = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.get("/information/allocation?" + payload, config);
};

const transactionPengajuan = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/transaction/pengajuan", payload, config);
};

const transactionInfaq = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/transaction/setoran_infaq", payload, config);
};

const checkExisting = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/information/check_existing", payload, config);
};

const sendDtl = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("/transaction/send_dtl", payload, config);
};
const transactionPenagihan = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("transaction/collect", payload, config);
};

const infoTertunggak = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("information/get_arrears", payload, config);
};

const infoPenagihan = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("information/info_collect", payload, config);
};
const listPenagihan = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("information/list_collect", payload, config);
};
const detailPenagihan = (payload, token) => {
  let config = {
    headers: {
      token: token,
    },
  };
  return axios.post("information/detail_collect", payload, config);
};
const grafikCabang = () => {
  return axios.get("information/info_grafik_cabang");
};

export default {
  authCheckUsername,
  authCheckUserRole,
  authCheckPassworLogin,
  infoSaldoPetugas,
  infoInOutMember,
  infoFinancing,
  infoSaving,
  infoRembug,
  infoGroup,
  infoGroupSaving,
  infoMemberFinancing,
  transSetoranDeposit,
  transSetoranProses,
  produkTabungan,
  registrasiTabungan,
  pencairanTabungan,
  TabunganDetail,
  prosesPencairan,
  infoDetailAngsuran,
  transactionSetorAngsuran,
  infoMemberSaving,
  transactionTabungan,
  infoGroupWakaf,
  infoMemberWakaf,
  transactionSetorWakaf,
  infoBirthPlace,
  infoPendidikan,
  infoOccupation,
  infoCity,
  infoDistrict,
  infoVillage,
  infoHouseStatus,
  infoSector,
  infoBusiness,
  infoHouseWater,
  infoHouseToilet,
  infoHouseFloor,
  infoHouseRoof,
  infoHouseWall,
  infoHouseSize,
  infoCheckKTP,
  infoAge,
  infoMember,
  memberDroping,
  infoSaldo,
  infoReason,
  transactionRegisAnggotaMasuk,
  transactionRegisAnggotaKeluar,
  infoAllocation,
  transactionPengajuan,
  transactionInfaq,
  transactionPenagihan,
  checkExisting,
  sendDtl,
  infoTertunggak,
  infoPenagihan,
  listPenagihan,
  detailPenagihan,
  grafikCabang,
};
