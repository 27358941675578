export default {
  async getPetugas() {
    return [
      { id: 1, nama: "Petugas A" },
      { id: 2, nama: "Petugas B" },
      { id: 3, nama: "Petugas C" },
    ];
  },
  async getLansat() {
    return [
      { id: 1, nama: "Lansat 1", petugas_id: 1, j_anggota: 10 },
      { id: 2, nama: "Lansat 2", petugas_id: 1, j_anggota: 15 },
      { id: 3, nama: "Lansat 3", petugas_id: 2, j_anggota: 8 },
      { id: 4, nama: "Lansat 4", petugas_id: 3, j_anggota: 20 },
      { id: 5, nama: "Lansat 5", petugas_id: 3, j_anggota: 12 },
    ];
  },

  async getId() {
    return {
      status: 200,
      data: {
        id: 1,
        petugas: "Dewi Kartika Fortuna",
        lansat: "001-Melati",
        tanggal: "2021-08-24",
        j_anggota: 10,
        //   Mekanisme Pengerjaan Kumpulan Pusat
        pembukaan: 10,
        pengumpulan_angsuran: 10,
        pengajuan_pembiayaan: 10,
        pencairan_pembiayaan: 10,
        penarikan_simpanan: 10,
        penyetoran_simpanan: 10,
        penutupan_materi_pendampingan: 10,
        urutan_prosedur_transaksi: 10,
        //   Interpersonal & Management
        penampilan_kerapihan: 10,
        komunikasi: 10,
        cara_penyampaian: 10,
        motivasi_sosialisasi: 10,
        ketepatan_waktu: 10,
        kerapihan_lansat: 10,
        penilaian_anggota: 10,

        catatan: "ini test dari api dummy",
        images: "https://www.abdikertaraharja.co.id/assets/images/bg/bg-slider-3.png",
      },
    };
  },
};
