<template>
  <div class="theme-switch" :class="{ 'dark-mode-bg': darkMode }" @click="toggleDarkMode">
    <div class="switch-thumb d-flex align-center justify-center" :class="{ 'dark-mode': darkMode }">
      <v-icon :class="{ 'dark-icon': darkMode, 'light-icon': !darkMode }" style="font-size: 16px">{{ darkMode ? "mdi-moon-waning-crescent" : "mdi-weather-sunny" }}</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      darkMode: JSON.parse(localStorage.getItem("darkMode")) || false,
    };
  },
  watch: {
    darkMode(val) {
      this.$vuetify.theme.dark = val;
      localStorage.setItem("darkMode", JSON.stringify(val));
    },
  },
  methods: {
    toggleDarkMode() {
      this.darkMode = !this.darkMode;
    },
  },
  mounted() {
    this.$vuetify.theme.dark = this.darkMode;
  },
};
</script>

<style scoped>
.theme-switch {
  width: 65px;
  height: 32px;
  border-radius: 20px;
  background: linear-gradient(145deg, #f0f0f0, #d9d9d9);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4px;
  cursor: pointer;
  position: relative;
  transition: background 0.3s ease-in-out, box-shadow 0.2s ease-in-out;
  box-shadow: inset 1px 1px 2px 1px rgba(253, 253, 253, 0.624), inset 2px 2px 6px 6px rgba(59, 59, 59, 0.3);
}

.dark-mode-bg {
  background: linear-gradient(145deg, #171717, #262626);
  box-shadow: inset 1px 1px 2px 1px rgba(23, 23, 23, 0.551), inset 2px 2px 6px 6px rgba(86, 86, 86, 0.3);
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
}

.switch-thumb {
  width: 28px;
  height: 28px;
  background: linear-gradient(145deg, #ffffff, #e0e0e0);
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 4px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out, background 0.3s ease-in-out, box-shadow 0.2s ease-in-out;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2), -1px -1px 4px rgba(255, 255, 255, 0.6);
}

.theme-switch:active .switch-thumb {
  transform: scale(0.9);
  box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.3), inset -2px -2px 2px rgba(255, 255, 255, 0.3);
}

.dark-mode {
  transform: translateX(30px);
  background: linear-gradient(145deg, #333, #424242);
  box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.3), inset -2px -2px 2px rgba(255, 255, 255, 0.1);
}

.light-icon {
  font-size: 18px;
  color: #ffcc00;
  transition: color 0.3s ease-in-out;
}

.dark-icon {
  font-size: 18px;
  color: #ffffff;
  transition: color 0.3s ease-in-out;
}
</style>
